@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
}

#_next {
  overflow-x: hidden;
}

a:link {
  scroll-behavior: auto !important;
}

@font-face {
  font-family: 'libertineregular';
  font-display: swap;
  src: url('/fonts/linux_libertine_regular.woff2');
}

@font-face {
  font-family: 'stigsamedium';
  font-display: swap;
  src: url('/fonts/stigsa_medium.woff2');
}

.libertine_regular {
  font-family: 'libertineregular';
}

.grid-parent {
  display: grid;
  grid-template-columns: 1fr;
  /* center elements */
}

.grid-parent div {
  grid-row-start: 1;
  grid-column-start: 1;
}
